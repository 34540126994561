<template>
  <div class="moondayDownloadContainer">
    <div class="moondayDownloadContainer__wrap">
      <div class="moondayDownload">
        <div class="moondayDownload__header">
          預覽
          <div class="close_btn" @click="$emit('close')">
            <img src="@/assets/月亮日記/close.svg" alt="" />
          </div>
        </div>
        <div class="moondayDownload__line hidden-mobile-down"></div>
        <div class="moondayDownload__body">
          <!----------------輸出在此-------------- -->
          <!-- !imgObj -->
          <div class="moondayNoteDetailWrap">
            <div
              class="moondayNoteDetail"
              id="moondayNoteDetail"
              v-if="!imgObj"
            >
              <div class="moondayNoteDetail__header">
                <div class="moondayNoteDetail__header__mood">
                  <img
                    :src="`/img/ConstellationImgs/${moondayNoteInfo.Constellation}.svg`"
                    @load="ready2 = true"
                  />
                </div>
                <div class="moondayNoteDetail__header__content">
                  <div class="moondayNoteDetail__header__content__title">
                    月亮{{ moondayNoteInfo.Constellation_CN }}日
                  </div>
                  <div>
                    {{ `${moondayNoteInfo.Date}  ${moondayNoteInfo.Time}` }}
                  </div>
                </div>

                <img
                  class="pic1"
                  src="@/assets/月亮日記/筆記裝飾_月亮.svg"
                  @load="ready3 = true"
                />
                <img
                  class="pic2"
                  src="@/assets/月亮日記/筆記裝飾星星大.svg"
                  @load="ready4 = true"
                />
                <img
                  class="pic3"
                  src="@/assets/月亮日記/筆記裝飾星星小.svg"
                  @load="ready5 = true"
                />
              </div>
              <!-- <div class="moondayNoteDetail__line"></div> -->
              <div class="moondayNoteDetail__body">
                <div
                  class="moondayNoteDetail__body__title"
                  :class="{ infoWrap: splitMoods.length > 2 }"
                >
                  <div class="moondayNoteDetail__body__title__moodsGroup">
                    <div
                      class="moondayNoteDetail__body__title__moodsGroup__mood"
                      v-for="(mood, index) in splitMoods"
                      :key="index"
                      :style="`background-color:${
                        emotionList.find((e) => e.id === mood).color
                      } `"
                    >
                      <img
                        :src="
                          emotionList.find((e) => e.id === mood).active_icon
                        "
                        @load="ready1 = true"
                      />
                    </div>
                  </div>
                  <div class="moondayNoteDetail__body__title__text">
                    <h2>我的心情</h2>
                  </div>
                </div>
                <div class="moondayNoteDetail__body__content">
                  <p v-html="replaceBr(moondayNoteInfo.MoodContent)"></p>
                </div>
              </div>
            </div>
            <div class="moondayNoteImg" id="moondayNoteImg" v-else>
              <img :src="imgObj" />
            </div>
          </div>
        </div>
        <div class="moondayDownload__line hidden-mobile-down"></div>
        <div class="moondayDownload__footer">
          <div class="moondayDownload__footer__notice">
            <div class="moondayDownload__footer__notice__pic">
              <img src="@/assets/提示燈泡.svg" alt="" />
            </div>
            <div class="moondayDownload__footer__notice__text">
              簡單兩步驟，輕鬆分享到社群～
            </div>
          </div>
          <div class="moondayDownload__footer__tutorial">
            <div class="moondayDownload__footer__tutorial__item">
              <span>1</span>
              <p class="hidden-mobile-down">按右鍵儲存圖片</p>
              <p class="hidden-mobile-up">長按圖片可儲存或分享</p>
            </div>
            <div class="moondayDownload__footer__tutorial__item">
              <span>2</span>
              <p class="hidden-mobile-down">上傳分享到社群</p>
              <p class="hidden-mobile-up">來與你的朋友分享吧</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toJpeg, toPng, toSvg } from "html-to-image";
import emotionList from "@/js/emotionList.js";

export default {
  props: ["moondayNoteInfo"],
  data() {
    return {
      emotionList,
      imgObj: "",
      ready1: false,
      ready2: false,
      ready3: false,
      ready4: false,
      ready5: false,
    };
  },
  created() {
    this.$store.commit("setLoading_force", true);
  },
  methods: {
    initPng() {
      let ua = navigator.userAgent;
      let iOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
      setTimeout(async () => {
        let node = document.getElementById("moondayNoteDetail");
        let res_1 = await toJpeg(node);
        let res_2 = await toJpeg(node);
        this.imgObj = res_2;
        this.$store.commit("setLoading_force", false);
      }, 500);
    },
    replaceBr(str) {
      return str.replace(/\n/g, "<br />");
    },
  },
  computed: {
    allReady() {
      return (
        this.ready1 && this.ready2 && this.ready3 && this.ready4 && this.ready5
      );
    },
    splitMoods() {
      return this.moondayNoteInfo.Moods.split("|");
    },
  },
  watch: {
    allReady() {
      if (this.allReady) {
        this.initPng();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
.moondayDownloadContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 300;
  background: rgba(240, 242, 244, 0.15);
  backdrop-filter: blur(30px);
  &__wrap {
    width: 720px;
    height: 860px;
    margin: 60px auto;
    pointer-events: none;
    @include pad {
      width: 100vw;
      height: auto;
      margin: 0;
    }
  }
  .moondayDownload {
    height: 100%;
    width: 100%;
    border-radius: 50px;
    background: #f0f2f4;
    box-shadow: 4px 4px 25px 0px rgba(0, 0, 0, 0.3), -4px -4px 10px 0px #fff,
      -4px -4px 16px 0px #fff;
    padding: 36px;
    position: relative;
    pointer-events: auto;
    @include mobile {
      min-height: 100vh;
      border-radius: 0;
      padding: 20px 0 0;
      box-shadow: none;
    }
    &__header {
      padding-bottom: 24px;
      color: #ab956c;
      text-align: center;
      font-size: 24px;
      font-weight: 700;

      @include mobile {
        padding-bottom: 16px;
        padding-right: 16px;
        padding-left: 16px;
        font-size: 20px;
        box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.16), -4px -4px 10px #ffffff,
          -4px -4px 16px #ffffff;
      }
      .close_btn {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        @include mobile {
          right: 16px;
          top: 14px;
          width: 36px;
          height: 36px;
        }
      }
    }
    &__line {
      height: 3px;
      width: 100%;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08) inset;
    }
    &__body {
      min-height: calc(100% - 96px - 200px + 72px);
      word-break: break-all;
      @include center;
      @include mobile {
        min-height: calc(100vh - 80px - 192px + 32px);
        padding-right: 16px;
        padding-left: 16px;
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #798da5;
      font-size: 15px;
      @include mobile {
        font-size: 13px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 15px;
        box-shadow: -2px -2px 8px 0px #fff, -2px -2px 3px 0px #fff,
          3px -2px 15px 0px rgba(0, 0, 0, 0.13);
      }
      &__notice {
        margin-top: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mobile {
          margin-top: 16px;
        }
        &__pic {
          width: 32px;
          height: 32px;
          margin-right: 12px;
          @include mobile {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }
        &__text {
          color: #ab956c;
          font-size: 18px;
          @include mobile {
            font-size: 15px;
          }
        }
      }
      &__tutorial {
        display: flex;
        margin-top: 22px;
        @include mobile {
          flex-direction: column;
        }
        &__item {
          display: flex;
          justify-items: center;
          color: #868686;
          font-size: 16px;
          margin-right: 16px;
          @include mobile {
            margin-bottom: 22px;
          }
          span {
            display: inline-flex;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: #ab956c;
            font-size: 14px;
            margin-right: 4px;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

//下載成圖片的區域
.moondayNoteDetailWrap {
  border: 1px solid #d7d7d7;
}
.moondayNoteDetail {
  width: 480px;
  height: 480px;
  padding: 16px 40px 40px;
  background-color: #f0f2f4;
  display: flex;
  flex-direction: column;
  @include mobile {
    width: calc(100vw - 32px);
    height: calc(100vw - 32px);
    padding: 12px 20px 28px;
  }
  &__header {
    font-weight: 500;
    color: #868686;
    font-size: 16px;
    height: 110px;
    padding: 17px 32px 21px;
    position: relative;
    display: flex;
    @include mobile {
      font-size: 13px;
      line-height: 18px;
      height: 92px;
      padding: 15px 16px;
      &__title {
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 8px;
      }
    }
    &__content {
      padding-top: 12px;
      font-weight: 500;
      color: #868686;
      font-size: 16px;
      @include mobile {
        padding-top: 9px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
      }
      &__title {
        color: $theme-color-2;
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 12px;
        @include mobile {
          font-size: 20px;
          line-height: 21px;
          margin-bottom: 8px;
          white-space: nowrap;
        }
      }
    }
    &__mood {
      width: 52px;
      height: 52px;
      @include center;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
      }
      @include mobile {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
    }
    & > img {
      position: absolute;
    }
    .pic1 {
      height: 124px;
      width: 124px;
      top: 0;
      right: 0;
      @include mobile {
        height: 106px;
        width: 106px;
      }
    }
    .pic2 {
      width: 16px;
      height: 16px;
      bottom: 30px;
      left: 0;
    }
    .pic3 {
      width: 10px;
      height: 10px;
      bottom: 20px;
      left: 14.35px;
    }
  }
  &__line {
    height: 3px;
    width: 100%;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08) inset;
  }
  &__body {
    margin-top: 28px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #868686;
    word-break: break-all;
    flex-grow: 1;
    height: 100%;
    padding: 16px 20px;
    margin-bottom: 28px;
    border-radius: 20px 35px 20px 35px;
    border: 1px solid #d2d2d2;
    @include mobile {
      margin-top: 36px;
      border: none;
      border-top: 1px solid #d2d2d2;
      border-radius: 0;
      padding: 12px 0;
      line-height: 1.4;
      font-size: 15px;
    }
    h2 {
      font-weight: 500;
      margin-bottom: 4px;
      color: #ab956c;
    }
    p {
      margin-bottom: 0;
    }
    &__title {
      display: flex;
      align-items: center;
      &.infoWrap {
        flex-direction: column;
        align-items: flex-start;
        .moondayNoteDetail__body__title__moodsGroup {
          margin-bottom: 8px;
        }
      }
      &__moodsGroup {
        display: flex;
        &__mood {
          flex-shrink: 0;
          margin-right: 16px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 7.5px;
          img {
            width: 100%;
          }
          @include mobile {
            width: 32px;
            height: 32px;
            margin-right: 12px;
            padding: 6.5px;
          }
        }
      }
      &__text {
        p {
          margin-top: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
        }
      }
    }

    &__content {
      margin-top: 20px;
      @include mobile {
        margin-top: 12px;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        @include mobile {
          -webkit-line-clamp: 5;
          max-height: calc(100vw - 32px - 240px);
        }
      }
    }
  }
}
.moondayNoteImg {
  width: 480px;
  height: 480px;
  img {
    width: 100%;
    height: 100%;
  }
  @include mobile {
    width: calc(100vw - 32px);
    height: calc(100vw - 32px);
  }
}
</style>
