import { render, staticRenderFns } from "./ExchangeProduct.vue?vue&type=template&id=3913be4f&scoped=true&"
import script from "./ExchangeProduct.vue?vue&type=script&lang=js&"
export * from "./ExchangeProduct.vue?vue&type=script&lang=js&"
import style0 from "./ExchangeProduct.vue?vue&type=style&index=0&id=3913be4f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3913be4f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\40420\\OneDrive\\桌面\\星學會\\DaMou-Frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3913be4f')) {
      api.createRecord('3913be4f', component.options)
    } else {
      api.reload('3913be4f', component.options)
    }
    module.hot.accept("./ExchangeProduct.vue?vue&type=template&id=3913be4f&scoped=true&", function () {
      api.rerender('3913be4f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/會員相關/ExchangeProduct.vue"
export default component.exports